import React, { useEffect } from "react";
import PropTypes from "prop-types";
import {
  flexRender,
  getCoreRowModel,
  getFilteredRowModel,
  getSortedRowModel,
  useReactTable,
} from "@tanstack/react-table";
import TableLoadingSkeleton from "./TableLoadingSkeleton";
import { SortingIcons } from "../common/icons";

function Table({
  data,
  columns,
  tableOptions,
  onRowClick,
  selectedRowIndex,
  noDataMessage,
  isLoading,
  enableHover,
  searchQuery,
  filterSetup,
  noResultsMessage,
  setNotice,
  layoutFixed,
}) {
  const table = useReactTable({
    data,
    columns,
    ...tableOptions,
    enableSortingRemoval: false,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
  });

  useEffect(() => {
    if (!filterSetup) return;

    if (filterSetup) {
      table.getHeaderGroups().map((headerGroup) => {
        headerGroup.headers.map((header) => {
          filterSetup.forEach((filter) => {
            if (header.column.id === filter.columnName) {
              header.column.setFilterValue(filter.filterValue);
            }
          });
        });
      });
    }
  }, [filterSetup]);

  useEffect(() => {
    setTimeout(checkHasFilterResults, 100);
  }, [searchQuery, filterSetup]);

  function checkHasFilterResults() {
    if (isLoading || !data?.length || table.getFilteredRowModel().rows.length) return;

    setNotice({
      kind: "warning",
      open: true,
      message: noResultsMessage,
    });
  }

  if (!isLoading && !data?.length) return <p>{noDataMessage}</p>;

  return (
    <table
      className={`table ${enableHover ? "table--hover" : ""} ${layoutFixed ? "table--layout-fixed" : ""}`}
    >
      <thead>
        {table.getHeaderGroups().map((headerGroup) => (
          <tr key={headerGroup.id}>
            {headerGroup.headers.map((header) => (
              <th
                key={header.id}
                style={{ width: `${header.getSize()}px` }}
                {...header.column.columnDef.headerProps}
              >
                <div>
                  {flexRender(header.column.columnDef.header, header.getContext())}
                  {header.column.getCanSort() && (
                    <span
                      data-testid={`${header.column.id}-sort-icon`}
                      onClick={header.column.getToggleSortingHandler()}
                    >
                      <SortingIcons className="sort-icon" size={20} />
                    </span>
                  )}
                </div>
              </th>
            ))}
          </tr>
        ))}
      </thead>
      <tbody>
        <TableRows
          isLoading={isLoading}
          columns={columns}
          onRowClick={onRowClick}
          table={table}
          selectedRowIndex={selectedRowIndex}
        />
      </tbody>
    </table>
  );
}

function TableRows({ columns, table, selectedRowIndex, onRowClick, isLoading }) {
  if (isLoading) return <TableLoadingSkeleton columns={columns.length} rows={24} />;

  return table.getRowModel().rows.map((row) => (
    <tr
      key={row.id}
      onClick={() => onRowClick(row)}
      className={`${selectedRowIndex === row.index ? "selected" : ""}`}
    >
      {row.getVisibleCells().map((cell) => (
        <td key={cell.id} {...cell.column.columnDef.cellProps}>
          {flexRender(cell.column.columnDef.cell, cell.getContext())}
        </td>
      ))}
    </tr>
  ));
}

Table.propTypes = {
  columns: PropTypes.array.isRequired,
  data: PropTypes.array,
  tableOptions: PropTypes.object,
  noDataMessage: PropTypes.string,
  onRowClick: PropTypes.func,
  selectedRowIndex: PropTypes.number,
  isLoading: PropTypes.bool,
  enableHover: PropTypes.bool,
  searchQuery: PropTypes.string,
  filterSetup: PropTypes.array,
  noResultsMessage: PropTypes.string,
  setNotice: PropTypes.func,
  layoutFixed: PropTypes.bool,
};

Table.defaultProps = {
  data: [],
  columns: [],
  tableOptions: {},
  noDataMessage: "No data available at this time.",
  onRowClick: () => {},
  selectedRowIndex: null,
  isLoading: false,
  enableHover: false,
  searchQuery: "",
  filterSetup: null,
  noResultsMessage: "No results found.",
  setNotice: () => {},
  layoutFixed: false,
};

TableRows.propTypes = {
  columns: PropTypes.array,
  table: PropTypes.object.isRequired,
  selectedRowIndex: PropTypes.number,
  onRowClick: PropTypes.func,
  isLoading: PropTypes.bool,
};

TableRows.defaultProps = {
  columns: [],
  selectedRowIndex: null,
  onRowClick: () => {},
  isLoading: false,
};

export default Table;
