import React, { useMemo, memo } from "react";
import { createColumnHelper } from "@tanstack/react-table";
import TableVirtualized from "../../common/TableVirtualized";
import colors from "../../../frontend/stylesheets/common/theme/_colors.module.scss";

const { white, secondaryText } = colors;

const mockData = [
  {
    validation_status: "Validated",
    validated_amount: 125.5,
    original_invoice_amount: 150.75,
    coverage_status: "Covered",
    ndc: "12345-6789-01",
    product_description: "Medication A",
    hcpcs_code: "J1234",
    hcpcs_modifier: "KX",
    date_of_service: "2024-01-01",
    quantity: 30,
    service_provider_id: "SP10001",
    pharmacy_id: "PH10001",
    billing_provider_id: "BP10001",
    prescriber_npi: "1234567891",
    plan_reimbursed_amount: 100.5,
    requested_rebate_amount: 15.0,
    payor_claim_number: "PCN0001",
    provider_claim_number: "PRCN0001",
    encrypted_patient_id: "EPID00001",
    plan_id: "PL10001",
    plan_name: "Basic Health Plan",
    client_id: "CL10001",
    client_name: "Client A",
    place_of_service_code: "11",
    invoiced_coverage_status: "Not Covered",
    unit_of_measure: "mg",
    configured_scrubs_in_order_of_priority_for_customer: ["Scrub A1", "Scrub A2", "Scrub A3"],
  },
  {
    validation_status: "Not Validated",
    validated_amount: 210.25,
    original_invoice_amount: 220.45,
    coverage_status: "Not Covered",
    ndc: "12345-6789-02",
    product_description: "Medication B",
    hcpcs_code: "J5678",
    hcpcs_modifier: "QW",
    date_of_service: "2024-01-02",
    quantity: 20,
    service_provider_id: "SP10002",
    pharmacy_id: "PH10002",
    billing_provider_id: "BP10002",
    prescriber_npi: "1234567892",
    plan_reimbursed_amount: 110.75,
    requested_rebate_amount: 20.5,
    payor_claim_number: "PCN0002",
    provider_claim_number: "PRCN0002",
    encrypted_patient_id: "EPID00002",
    plan_id: "PL10002",
    plan_name: "Advanced Health Plan",
    client_id: "CL10002",
    client_name: "Client B",
    place_of_service_code: "22",
    invoiced_coverage_status: "Covered",
    unit_of_measure: "ml",
    configured_scrubs_in_order_of_priority_for_customer: ["Scrub B1", "Scrub B2"],
  },
  {
    validation_status: "Validated",
    validated_amount: 95.0,
    original_invoice_amount: 105.25,
    coverage_status: "Covered",
    ndc: "12345-6789-03",
    product_description: "Medication C",
    hcpcs_code: "J9101",
    hcpcs_modifier: "KX",
    date_of_service: "2024-01-03",
    quantity: 50,
    service_provider_id: "SP10003",
    pharmacy_id: "PH10003",
    billing_provider_id: "BP10003",
    prescriber_npi: "1234567893",
    plan_reimbursed_amount: 75.5,
    requested_rebate_amount: 10.0,
    payor_claim_number: "PCN0003",
    provider_claim_number: "PRCN0003",
    encrypted_patient_id: "EPID00003",
    plan_id: "PL10003",
    plan_name: "Premium Health Plan",
    client_id: "CL10003",
    client_name: "Client C",
    place_of_service_code: "11",
    invoiced_coverage_status: "Not Covered",
    unit_of_measure: "mg",
    configured_scrubs_in_order_of_priority_for_customer: ["Scrub C1"],
  },
  {
    validation_status: "Not Validated",
    validated_amount: 130.0,
    original_invoice_amount: 145.0,
    coverage_status: "Covered",
    ndc: "12345-6789-04",
    product_description: "Medication D",
    hcpcs_code: "J1111",
    hcpcs_modifier: "QW",
    date_of_service: "2024-01-04",
    quantity: 10,
    service_provider_id: "SP10004",
    pharmacy_id: "PH10004",
    billing_provider_id: "BP10004",
    prescriber_npi: "1234567894",
    plan_reimbursed_amount: 105.0,
    requested_rebate_amount: 12.5,
    payor_claim_number: "PCN0004",
    provider_claim_number: "PRCN0004",
    encrypted_patient_id: "EPID00004",
    plan_id: "PL10004",
    plan_name: "Enhanced Health Plan",
    client_id: "CL10004",
    client_name: "Client D",
    place_of_service_code: "23",
    invoiced_coverage_status: "Covered",
    unit_of_measure: "ml",
    configured_scrubs_in_order_of_priority_for_customer: ["Scrub D1", "Scrub D2", "Scrub D3"],
  },
  {
    validation_status: "Validated",
    validated_amount: 200.0,
    original_invoice_amount: 225.0,
    coverage_status: "Not Covered",
    ndc: "12345-6789-05",
    product_description: "Medication E",
    hcpcs_code: "J5678",
    hcpcs_modifier: "KX",
    date_of_service: "2024-01-05",
    quantity: 60,
    service_provider_id: "SP10005",
    pharmacy_id: "PH10005",
    billing_provider_id: "BP10005",
    prescriber_npi: "1234567895",
    plan_reimbursed_amount: 120.5,
    requested_rebate_amount: 25.0,
    payor_claim_number: "PCN0005",
    provider_claim_number: "PRCN0005",
    encrypted_patient_id: "EPID00005",
    plan_id: "PL10005",
    plan_name: "Standard Plus Plan",
    client_id: "CL10005",
    client_name: "Client E",
    place_of_service_code: "21",
    invoiced_coverage_status: "Not Covered",
    unit_of_measure: "mg",
    configured_scrubs_in_order_of_priority_for_customer: ["Scrub E1", "Scrub E2"],
  },
  {
    validation_status: "Validated",
    validated_amount: 80.0,
    original_invoice_amount: 95.0,
    coverage_status: "Covered",
    ndc: "12345-6789-06",
    product_description: "Medication F",
    hcpcs_code: "J1234",
    hcpcs_modifier: "QW",
    date_of_service: "2024-01-06",
    quantity: 45,
    service_provider_id: "SP10006",
    pharmacy_id: "PH10006",
    billing_provider_id: "BP10006",
    prescriber_npi: "1234567896",
    plan_reimbursed_amount: 65.0,
    requested_rebate_amount: 5.0,
    payor_claim_number: "PCN0006",
    provider_claim_number: "PRCN0006",
    encrypted_patient_id: "EPID00006",
    plan_id: "PL10006",
    plan_name: "Basic Plan",
    client_id: "CL10006",
    client_name: "Client F",
    place_of_service_code: "11",
    invoiced_coverage_status: "Covered",
    unit_of_measure: "ml",
    configured_scrubs_in_order_of_priority_for_customer: ["Scrub F1", "Scrub F2"],
  },
  {
    validation_status: "Not Validated",
    validated_amount: 110.0,
    original_invoice_amount: 125.0,
    coverage_status: "Not Covered",
    ndc: "12345-6789-07",
    product_description: "Medication G",
    hcpcs_code: "J5678",
    hcpcs_modifier: "KX",
    date_of_service: "2024-01-07",
    quantity: 25,
    service_provider_id: "SP10007",
    pharmacy_id: "PH10007",
    billing_provider_id: "BP10007",
    prescriber_npi: "1234567897",
    plan_reimbursed_amount: 85.0,
    requested_rebate_amount: 8.5,
    payor_claim_number: "PCN0007",
    provider_claim_number: "PRCN0007",
    encrypted_patient_id: "EPID00007",
    plan_id: "PL10007",
    plan_name: "Premium Plan",
    client_id: "CL10007",
    client_name: "Client G",
    place_of_service_code: "12",
    invoiced_coverage_status: "Not Covered",
    unit_of_measure: "mg",
    configured_scrubs_in_order_of_priority_for_customer: ["Scrub G1", "Scrub G2", "Scrub G3"],
  },
  {
    validation_status: "Validated",
    validated_amount: 115.0,
    original_invoice_amount: 135.0,
    coverage_status: "Covered",
    ndc: "12345-6789-08",
    product_description: "Medication H",
    hcpcs_code: "J9101",
    hcpcs_modifier: "QW",
    date_of_service: "2024-01-08",
    quantity: 15,
    service_provider_id: "SP10008",
    pharmacy_id: "PH10008",
    billing_provider_id: "BP10008",
    prescriber_npi: "1234567898",
    plan_reimbursed_amount: 90.0,
    requested_rebate_amount: 12.0,
    payor_claim_number: "PCN0008",
    provider_claim_number: "PRCN0008",
    encrypted_patient_id: "EPID00008",
    plan_id: "PL10008",
    plan_name: "Enhanced Plus Plan",
    client_id: "CL10008",
    client_name: "Client H",
    place_of_service_code: "23",
    invoiced_coverage_status: "Covered",
    unit_of_measure: "mg",
    configured_scrubs_in_order_of_priority_for_customer: ["Scrub H1"],
  },
  {
    validation_status: "Not Validated",
    validated_amount: 155.0,
    original_invoice_amount: 165.0,
    coverage_status: "Covered",
    ndc: "12345-6789-09",
    product_description: "Medication I",
    hcpcs_code: "J1111",
    hcpcs_modifier: "KX",
    date_of_service: "2024-01-09",
    quantity: 35,
    service_provider_id: "SP10009",
    pharmacy_id: "PH10009",
    billing_provider_id: "BP10009",
    prescriber_npi: "1234567899",
    plan_reimbursed_amount: 125.5,
    requested_rebate_amount: 15.0,
    payor_claim_number: "PCN0009",
    provider_claim_number: "PRCN0009",
    encrypted_patient_id: "EPID00009",
    plan_id: "PL10009",
    plan_name: "Standard Health Plan",
    client_id: "CL10009",
    client_name: "Client I",
    place_of_service_code: "21",
    invoiced_coverage_status: "Not Covered",
    unit_of_measure: "ml",
    configured_scrubs_in_order_of_priority_for_customer: ["Scrub I1", "Scrub I2"],
  },
  {
    validation_status: "Validated",
    validated_amount: 185.0,
    original_invoice_amount: 205.0,
    coverage_status: "Not Covered",
    ndc: "12345-6789-10",
    product_description: "Medication J",
    hcpcs_code: "J1234",
    hcpcs_modifier: "QW",
    date_of_service: "2024-01-10",
    quantity: 40,
    service_provider_id: "SP10010",
    pharmacy_id: "PH10010",
    billing_provider_id: "BP10010",
    prescriber_npi: "1234567890",
    plan_reimbursed_amount: 145.0,
    requested_rebate_amount: 18.5,
    payor_claim_number: "PCN0010",
    provider_claim_number: "PRCN0010",
    encrypted_patient_id: "EPID00010",
    plan_id: "PL10010",
    plan_name: "Comprehensive Health Plan",
    client_id: "CL10010",
    client_name: "Client J",
    place_of_service_code: "12",
    invoiced_coverage_status: "Covered",
    unit_of_measure: "mg",
    configured_scrubs_in_order_of_priority_for_customer: ["Scrub J1", "Scrub J2", "Scrub J3"],
  },
  {
    validation_status: "Validated",
    validated_amount: 185.0,
    original_invoice_amount: 205.0,
    coverage_status: "Not Covered",
    ndc: "12345-6789-10",
    product_description: "Medication J",
    hcpcs_code: "J1234",
    hcpcs_modifier: "QW",
    date_of_service: "2024-01-10",
    quantity: 40,
    service_provider_id: "SP10010",
    pharmacy_id: "PH10010",
    billing_provider_id: "BP10010",
    prescriber_npi: "1234567890",
    plan_reimbursed_amount: 145.0,
    requested_rebate_amount: 18.5,
    payor_claim_number: "PCN0010",
    provider_claim_number: "PRCN0010",
    encrypted_patient_id: "EPID00010",
    plan_id: "PL10010",
    plan_name: "Comprehensive Health Plan",
    client_id: "CL10010",
    client_name: "Client J",
    place_of_service_code: "12",
    invoiced_coverage_status: "Covered",
    unit_of_measure: "mg",
    configured_scrubs_in_order_of_priority_for_customer: ["Scrub J1", "Scrub J2", "Scrub J3"],
  },
  {
    validation_status: "Validated",
    validated_amount: 185.0,
    original_invoice_amount: 205.0,
    coverage_status: "Not Covered",
    ndc: "12345-6789-10",
    product_description: "Medication J",
    hcpcs_code: "J1234",
    hcpcs_modifier: "QW",
    date_of_service: "2024-01-10",
    quantity: 40,
    service_provider_id: "SP10010",
    pharmacy_id: "PH10010",
    billing_provider_id: "BP10010",
    prescriber_npi: "1234567890",
    plan_reimbursed_amount: 145.0,
    requested_rebate_amount: 18.5,
    payor_claim_number: "PCN0010",
    provider_claim_number: "PRCN0010",
    encrypted_patient_id: "EPID00010",
    plan_id: "PL10010",
    plan_name: "Comprehensive Health Plan",
    client_id: "CL10010",
    client_name: "Client J",
    place_of_service_code: "12",
    invoiced_coverage_status: "Covered",
    unit_of_measure: "mg",
    configured_scrubs_in_order_of_priority_for_customer: ["Scrub J1", "Scrub J2", "Scrub J3"],
  },
  {
    validation_status: "Validated",
    validated_amount: 185.0,
    original_invoice_amount: 205.0,
    coverage_status: "Not Covered",
    ndc: "12345-6789-10",
    product_description: "Medication J",
    hcpcs_code: "J1234",
    hcpcs_modifier: "QW",
    date_of_service: "2024-01-10",
    quantity: 40,
    service_provider_id: "SP10010",
    pharmacy_id: "PH10010",
    billing_provider_id: "BP10010",
    prescriber_npi: "1234567890",
    plan_reimbursed_amount: 145.0,
    requested_rebate_amount: 18.5,
    payor_claim_number: "PCN0010",
    provider_claim_number: "PRCN0010",
    encrypted_patient_id: "EPID00010",
    plan_id: "PL10010",
    plan_name: "Comprehensive Health Plan",
    client_id: "CL10010",
    client_name: "Client J",
    place_of_service_code: "12",
    invoiced_coverage_status: "Covered",
    unit_of_measure: "mg",
    configured_scrubs_in_order_of_priority_for_customer: ["Scrub J1", "Scrub J2", "Scrub J3"],
  },
  {
    validation_status: "Validated",
    validated_amount: 185.0,
    original_invoice_amount: 205.0,
    coverage_status: "Not Covered",
    ndc: "12345-6789-10",
    product_description: "Medication J",
    hcpcs_code: "J1234",
    hcpcs_modifier: "QW",
    date_of_service: "2024-01-10",
    quantity: 40,
    service_provider_id: "SP10010",
    pharmacy_id: "PH10010",
    billing_provider_id: "BP10010",
    prescriber_npi: "1234567890",
    plan_reimbursed_amount: 145.0,
    requested_rebate_amount: 18.5,
    payor_claim_number: "PCN0010",
    provider_claim_number: "PRCN0010",
    encrypted_patient_id: "EPID00010",
    plan_id: "PL10010",
    plan_name: "Comprehensive Health Plan",
    client_id: "CL10010",
    client_name: "Client J",
    place_of_service_code: "12",
    invoiced_coverage_status: "Covered",
    unit_of_measure: "mg",
    configured_scrubs_in_order_of_priority_for_customer: ["Scrub J1", "Scrub J2", "Scrub J3"],
  },
  {
    validation_status: "Validated",
    validated_amount: 185.0,
    original_invoice_amount: 205.0,
    coverage_status: "Not Covered",
    ndc: "12345-6789-10",
    product_description: "Medication J",
    hcpcs_code: "J1234",
    hcpcs_modifier: "QW",
    date_of_service: "2024-01-10",
    quantity: 40,
    service_provider_id: "SP10010",
    pharmacy_id: "PH10010",
    billing_provider_id: "BP10010",
    prescriber_npi: "1234567890",
    plan_reimbursed_amount: 145.0,
    requested_rebate_amount: 18.5,
    payor_claim_number: "PCN0010",
    provider_claim_number: "PRCN0010",
    encrypted_patient_id: "EPID00010",
    plan_id: "PL10010",
    plan_name: "Comprehensive Health Plan",
    client_id: "CL10010",
    client_name: "Client J",
    place_of_service_code: "12",
    invoiced_coverage_status: "Covered",
    unit_of_measure: "mg",
    configured_scrubs_in_order_of_priority_for_customer: ["Scrub J1", "Scrub J2", "Scrub J3"],
  },
  {
    validation_status: "Validated",
    validated_amount: 185.0,
    original_invoice_amount: 205.0,
    coverage_status: "Not Covered",
    ndc: "12345-6789-10",
    product_description: "Medication J",
    hcpcs_code: "J1234",
    hcpcs_modifier: "QW",
    date_of_service: "2024-01-10",
    quantity: 40,
    service_provider_id: "SP10010",
    pharmacy_id: "PH10010",
    billing_provider_id: "BP10010",
    prescriber_npi: "1234567890",
    plan_reimbursed_amount: 145.0,
    requested_rebate_amount: 18.5,
    payor_claim_number: "PCN0010",
    provider_claim_number: "PRCN0010",
    encrypted_patient_id: "EPID00010",
    plan_id: "PL10010",
    plan_name: "Comprehensive Health Plan",
    client_id: "CL10010",
    client_name: "Client J",
    place_of_service_code: "12",
    invoiced_coverage_status: "Covered",
    unit_of_measure: "mg",
    configured_scrubs_in_order_of_priority_for_customer: ["Scrub J1", "Scrub J2", "Scrub J3"],
  },
  {
    validation_status: "Validated",
    validated_amount: 185.0,
    original_invoice_amount: 205.0,
    coverage_status: "Not Covered",
    ndc: "12345-6789-10",
    product_description: "Medication J",
    hcpcs_code: "J1234",
    hcpcs_modifier: "QW",
    date_of_service: "2024-01-10",
    quantity: 40,
    service_provider_id: "SP10010",
    pharmacy_id: "PH10010",
    billing_provider_id: "BP10010",
    prescriber_npi: "1234567890",
    plan_reimbursed_amount: 145.0,
    requested_rebate_amount: 18.5,
    payor_claim_number: "PCN0010",
    provider_claim_number: "PRCN0010",
    encrypted_patient_id: "EPID00010",
    plan_id: "PL10010",
    plan_name: "Comprehensive Health Plan",
    client_id: "CL10010",
    client_name: "Client J",
    place_of_service_code: "12",
    invoiced_coverage_status: "Covered",
    unit_of_measure: "mg",
    configured_scrubs_in_order_of_priority_for_customer: ["Scrub J1", "Scrub J2", "Scrub J3"],
  },
  {
    validation_status: "Validated",
    validated_amount: 185.0,
    original_invoice_amount: 205.0,
    coverage_status: "Not Covered",
    ndc: "12345-6789-10",
    product_description: "Medication J",
    hcpcs_code: "J1234",
    hcpcs_modifier: "QW",
    date_of_service: "2024-01-10",
    quantity: 40,
    service_provider_id: "SP10010",
    pharmacy_id: "PH10010",
    billing_provider_id: "BP10010",
    prescriber_npi: "1234567890",
    plan_reimbursed_amount: 145.0,
    requested_rebate_amount: 18.5,
    payor_claim_number: "PCN0010",
    provider_claim_number: "PRCN0010",
    encrypted_patient_id: "EPID00010",
    plan_id: "PL10010",
    plan_name: "Comprehensive Health Plan",
    client_id: "CL10010",
    client_name: "Client J",
    place_of_service_code: "12",
    invoiced_coverage_status: "Covered",
    unit_of_measure: "mg",
    configured_scrubs_in_order_of_priority_for_customer: ["Scrub J1", "Scrub J2", "Scrub J3"],
  },
];

const PinnedColumnsBorder = () => (
  <div
    style={{
      position: "absolute",
      top: 0,
      right: 0,
      width: 1,
      height: "100%",
      backgroundColor: secondaryText,
      zIndex: 2,
    }}
  />
);

const PinnedColumnsShadow = () => (
  <div
    style={{
      zIndex: 200,
      position: "absolute",
      top: 0,
      width: "12px",
      left: 536,
      background: "linear-gradient(90deg, rgba(0,0,0,0.1) 0%, rgba(255,255,255,0) 100%)",
      height: "100%",
    }}
  />
);

const pinnedCellStyles = {
  position: "sticky",
  backgroundColor: white,
};

const pinnedHeaderStyles = {
  position: "sticky",
  zIndex: 2,
};

const Spreadsheet = () => {
  const columns = useMemo(() => {
    const columnHelper = createColumnHelper();

    return [
      columnHelper.accessor("validation_status", {
        header: () => (
          <div>
            Validation
            <br /> Status
          </div>
        ),
        cell: (info) => info.getValue(),
        size: 100,
        cellProps: { style: { left: 0, ...pinnedCellStyles } },
        headerProps: {
          style: { width: 100, left: 0, ...pinnedHeaderStyles },
        },
      }),
      columnHelper.accessor("validated_amount", {
        header: () => (
          <div>
            Validation
            <br /> Amount
          </div>
        ),
        cell: (info) => info.getValue(),
        size: 100,
        cellProps: { style: { left: 124, ...pinnedCellStyles } },
        headerProps: {
          style: { width: 100, left: 124, ...pinnedHeaderStyles },
        },
      }),
      columnHelper.accessor("original_invoice_amount", {
        header: () => (
          <div>
            Original Invoice
            <br /> Amount
          </div>
        ),
        cell: (info) => info.getValue(),
        size: 140,
        cellProps: { style: { left: 248, ...pinnedCellStyles } },
        headerProps: {
          style: { width: 140, left: 248, ...pinnedHeaderStyles },
        },
      }),
      columnHelper.accessor("coverage_status", {
        header: () => (
          <div>
            Coverage
            <br /> Status
            <PinnedColumnsBorder />
          </div>
        ),
        cell: (info) => (
          <div>
            {info.getValue()}
            <PinnedColumnsBorder />
          </div>
        ),
        size: 100,
        cellProps: {
          style: { left: 412, ...pinnedCellStyles },
        },
        headerProps: {
          style: { width: 100, left: 412, ...pinnedHeaderStyles },
        },
      }),
      columnHelper.accessor("ndc", {
        header: "NDC",
        cell: (info) => info.getValue(),
        size: 96,
      }),
      columnHelper.accessor("product_description", {
        header: "Product Description",
        cell: (info) => info.getValue(),
        size: 150,
      }),
      columnHelper.accessor("hcpcs_code", {
        header: "HCPCS Code",
        cell: (info) => info.getValue(),
        size: 120,
      }),
      columnHelper.accessor("hcpcs_modifier", {
        header: "HCPCS Modifier",
        cell: (info) => info.getValue(),
        size: 140,
      }),
      columnHelper.accessor("date_of_service", {
        header: "Date of Service",
        cell: (info) => info.getValue(),
        size: 120,
      }),
      columnHelper.accessor("quantity", {
        header: "Quantity",
        cell: (info) => info.getValue(),
        size: 96,
      }),
      columnHelper.accessor("service_provider_id", {
        header: "Service Provider ID",
        cell: (info) => info.getValue(),
        size: 140,
      }),
      columnHelper.accessor("pharmacy_id", {
        header: "Pharmacy ID",
        cell: (info) => info.getValue(),
        size: 120,
      }),
      columnHelper.accessor("billing_provider_id", {
        header: "Billing Provider ID",
        cell: (info) => info.getValue(),
        size: 140,
      }),
      columnHelper.accessor("prescriber_npi", {
        header: "Prescriber NPI",
        cell: (info) => info.getValue(),
        size: 120,
      }),
      columnHelper.accessor("plan_reimbursed_amount", {
        header: "Plan Reimbursed Amount",
        cell: (info) => info.getValue(),
        size: 180,
      }),
      columnHelper.accessor("requested_rebate_amount", {
        header: "Requested Rebate Amount",
        cell: (info) => info.getValue(),
        size: 190,
      }),
      columnHelper.accessor("payor_claim_number", {
        header: "Payor Claim Number",
        cell: (info) => info.getValue(),
        size: 160,
      }),
      columnHelper.accessor("provider_claim_number", {
        header: "Provider Claim Number",
        cell: (info) => info.getValue(),
        size: 180,
      }),
      columnHelper.accessor("encrypted_patient_id", {
        header: "Encrypted Patient ID",
        cell: (info) => info.getValue(),
        size: 160,
      }),
      columnHelper.accessor("plan_id", {
        header: "Plan ID",
        cell: (info) => info.getValue(),
        size: 96,
      }),
      columnHelper.accessor("plan_name", {
        header: "Plan Name",
        cell: (info) => info.getValue(),
        size: 96,
      }),
      columnHelper.accessor("client_id", {
        header: "Client ID",
        cell: (info) => info.getValue(),
        size: 96,
      }),
      columnHelper.accessor("client_name", {
        header: "Client Name",
        cell: (info) => info.getValue(),
        size: 120,
      }),
      columnHelper.accessor("place_of_service_code", {
        header: "Place of Service Code",
        cell: (info) => info.getValue(),
        size: 160,
      }),
      columnHelper.accessor("invoiced_coverage_status", {
        header: "Invoiced Coverage Status",
        cell: (info) => info.getValue(),
        size: 180,
      }),
      columnHelper.accessor("unit_of_measure", {
        header: "Unit of Measure",
        cell: (info) => info.getValue(),
        size: 140,
      }),
      columnHelper.accessor("configured_scrubs_in_order_of_priority_for_customer", {
        header: "Configured Scrubs in Order of Priority for Customer",
        cell: (info) => info.getValue(),
        size: 340,
      }),
    ];
  }, []);

  return (
    <div style={{ position: "relative", left: -1, overflowY: "hidden" }}>
      <TableVirtualized
        data={mockData || []}
        isLoading={false}
        columns={columns}
        styleVariant="table--secondary table--layout-fixed"
        noDataMessage="No results found that matched your search"
      />
      <PinnedColumnsShadow />
    </div>
  );
};

export default memo(Spreadsheet);
