import React from "react";
import PropTypes from "prop-types";
import { Grid, ListItem, ListItemText, List, Link, Breadcrumbs, Typography } from "@mui/material";

const CustomerDetails = ({ customer }) => {
  return (
    <div className="customer-details">
      <Grid container justifyContent="center">
        <Grid item xs={10}>
          <div role="presentation">
            <Breadcrumbs aria-label="breadcrumb" className="breadcrumbs">
              <Link underline="hover" color="inherit" href="/customers">
                Customers
              </Link>
              <Typography color="text.primary">Customer Details</Typography>
            </Breadcrumbs>
            <h1 className="customer-selection__title">Customer Details</h1>
          </div>
        </Grid>
      </Grid>
      <Grid container spacing={2} justifyContent="center">
        <Grid item xs={10}>
          <Grid container>
            <Grid item>
              <List>
                <ListItem disableGutters>
                  <ListItemText primary="Name" secondary={customer.name} />
                </ListItem>
              </List>
            </Grid>
          </Grid>
          <Grid container>
            <Grid item>
              <Link href={`/customers/${customer.id}/edit`}>Edit Customer</Link>
            </Grid>
          </Grid>
          <Grid container>
            <Grid item xs={12}>
              <h2>Rules</h2>
              <Link href={`/customers/${customer.id}/rules/new`}>New Rule</Link>
              <List>
                {customer.rules.map((rule) => (
                  <ListItem key={rule.id} disableGutters>
                    <ListItemText
                      primary={`${rule.name}`}
                      secondary={`Priority: ${rule.priority || "N/A"}`}
                    />
                    <Link
                      href={`/rules/${rule.id}`}
                      data-turbo-method="delete"
                      style={{ marginLeft: "10px" }}
                    >
                      Delete
                    </Link>
                  </ListItem>
                ))}
              </List>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
};

CustomerDetails.propTypes = {
  customer: PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string,
    rules: PropTypes.arrayOf(PropTypes.object),
  }).isRequired,
};

export default CustomerDetails;
